.pedido-recibido {
    background-color: #f0f8ff;
  }
  
  .pedido-confirmado {
    background-color: #d0ffd0;
  }
  
  .pedido-enviado {
    background-color: #07f007;
  }
  
  .pedido-rechazado {
    background-color: #ffd0d0;
  }

  
  